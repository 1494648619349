.change-class-name {
  padding: 0 64px 40px;

  @include media($sm) {
    padding: 0 24px 24px;
  }

  &__title {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #292929;
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__input {
    margin-bottom: 16px;
    max-width: 410px;
  }
}