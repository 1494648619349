.results {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.14px;

  &__not-paid {
    margin-bottom: 16px;
    max-width: 502px;
    line-height: 140%;
    color: #0f0f0f;

    h3 {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: 0.18px;
      color: #ff3b30;
    }
  }

  &__download {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    padding: 0 16px;
    min-height: 48px;
    max-width: 292px;
    border: 1px solid #4fb546;
    border-radius: 52px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    background-color: rgba(79, 181, 70, 0.98);
  }

  &__pupils {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 12px;
  }

  &__pupil {
    margin-bottom: 16px;

    p {
      margin-bottom: 0 !important;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: #0f0f0f;
  }

  &__warning {
    margin-bottom: 16px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 12px;
  }

  &__btn {
    margin-bottom: 16px;
  }

  &__privacy-policy {
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.12px;
    color: #000;

    a {
      color: #1d6ef6;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
