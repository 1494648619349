.table {
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.12px;

  @include media($sm) {
    width: 100%;
  }

  th,
  td {
    width: 40px;
    text-align: left;

    &:not(:last-of-type) {
      padding-right: 8px;
    }
  }

  &__row {
    color: #949494;
  }

  &__cell {
    &--normal {
      font-weight: 400;
    }

    &--bold {
      font-weight: 600;
    }

    &--red {
      color: #ff3b30;
    }

    &--green {
      color: #00ad42;
    }

    &--gray {
      color: #949494;
    }
  }
}