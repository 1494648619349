.dropdown {
  position: relative;
  display: inline-block;
  max-width: 215px;

  &:hover {
    .dropdown__wrap {
      display: flex;
    }
  }

  &__logout {
    img {
      display: block;

      @include media($sm) {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__spacer {
    height: 8px;
    pointer-events: none;
  }

  &__wrap {
    position: absolute;
    right: 0;
    top: 56px;
    z-index: 10;
    display: none;
    padding-top: 8px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    padding: 24px;
    min-width: 215px;
    border-radius: 16px;
    box-shadow: 0 10px 30px 0 rgba(34, 34, 34, 0.15);
    background-color: #fff;
    gap: 16px;

    @include media($sm) {
      top: 36px;
    }
  }

  &__item {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #222;
    cursor: pointer;

    span {
      color: #ff3b30;
    }

    &--archive {
      color: #949494;
    }

    &--logout {
      color: #ff3b30;
    }
  }
}
