@import "./helpers/reset";
@import "./helpers/mixins";
@import "./helpers/variables";

$path-img: "../../img/";

@import "legacy";
@import "design-system";
@import "fonts";
@import "global";
@import "components";
@import "settings";
@import "landing";
@import "orders";
@import "other";
