@font-face {
  src:
    url("../../fonts/gilroy-bold-webfont.woff2") format("woff2"),
    url("../../fonts/gilroy-bold-webfont.woff") format("woff");
  font-family: "Gilroy";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src:
    url("../../fonts/gilroy-medium-webfont.woff2") format("woff2"),
    url("../../fonts/gilroy-medium-webfont.woff") format("woff");
  font-family: "Gilroy";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src:
    url("../../fonts/gilroy-semibold-webfont.woff2") format("woff2"),
    url("../../fonts/gilroy-semibold-webfont.woff") format("woff");
  font-family: "Gilroy";
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  src:
    url("../../fonts/gilroy-regular.woff2") format("woff2"),
    url("../../fonts/gilroy-regular.woff") format("woff");
  font-family: "Gilroy";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src:
    url("../../fonts/mulish/Mulish-ExtraBold.woff2") format("woff2"),
    url("../../fonts/mulish/Mulish-ExtraBold.woff") format("woff");
  font-family: "Mulish";
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src:
    url("../../fonts/mulish/Mulish-SemiBold.woff2") format("woff2"),
    url("../../fonts/mulish/Mulish-SemiBold.woff") format("woff");
  font-family: "Mulish";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src:
    url("../../fonts/mulish/Mulish-Medium.woff2") format("woff2"),
    url("../../fonts/mulish/Mulish-Medium.woff") format("woff");
  font-family: "Mulish";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src:
    url("../../fonts/mulish/Mulish-Regular.woff2") format("woff2"),
    url("../../fonts/mulish/Mulish-Regular.woff") format("woff");
  font-family: "Mulish";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
