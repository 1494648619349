.order-form {
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0 2px 8px 0 rgba(34, 34, 34, 0.08);
  background-color: #fff;

  @include media($sm) {
    padding: 24px 8px;
  }

  &:not(:last-of-type) {
    margin-bottom: 24px;

    @include media($sm) {
      margin-bottom: 36px;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
    font-size: 14px;
    line-height: 95%;

    @include media($sm) {
      flex-direction: column;
      margin-bottom: 36px;
      gap: 8px;
    }
  }

  &__head-item {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include media($sm) {
      &:last-of-type {
        flex-direction: column-reverse;
        gap: 4px;
      }
    }
  }

  &__number {
    font-weight: 600;
    font-size: 16px;
    line-height: 95%;
    color: #292929;
  }

  &__title {
    font-weight: 600;
    color: #949494;
  }

  &__status {
    font-weight: 600;

    &--not-paid {
      color: #ff3b30;
    }
  }

  &__date {
    text-align: right;
    color: #949494;

    @include media($sm) {
      text-align: left;
    }
  }

  &__tabs-vertical {
    .tabs {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      border-bottom: 1px solid #dadada;

      @include media($sm) {
        margin-bottom: 16px;
        overflow-x: scroll;
        white-space: nowrap;
        gap: 16px;
      }

      li {
        a {
          display: inline-block;
          padding: 0 10px 11px;

          @include media($sm) {
            padding: 0 8px 8px;
          }

          &.active {
            border-bottom: 3px solid #ff673d;
            color: #292929;

            b {
              color: #949494;
            }
          }
        }
      }
    }
  }

  &__tab {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: #949494;
  }

  &__text {
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.14px;
      color: #0f0f0f;

      a {
        color: #1d6ef6;

        &:hover {
          text-decoration: underline;
        }
      }

      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media($sm) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__btn {
    @include media($sm) {
      width: 100%;
    }
  }

  &__delete {
    font-weight: 400;
    font-size: 14px;
    line-height: 95%;
    color: #ff3b30;
  }

  &__table {
    margin-bottom: 24px;
    width: 100%;
    max-width: 571px;
    text-align: left;

    @include media($sm) {
      font-size: 14px;
    }

    th {
      padding: 0;
      font-weight: 600;
      line-height: 100%;
      color: #292929;
    }

    td {
      padding: 0;
    }
  }

  &__students {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .empty {
      display: block;
      padding: 0 16px;
      max-width: 420px;
      font-weight: 500;
      line-height: 130%;
      color: #949494;
    }
  }
}
