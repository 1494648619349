.add-pupil {
  &__head {
    margin-bottom: 16px;
  }

  &__row {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  &__subtitle {
    margin-bottom: 8px;
    padding: 0 8px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.16px;
    color: #292929;
  }

  &__agreement {
    margin-bottom: 24px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.14px;
    color: #0f0f0f;
  }

  &__btn {
    margin-bottom: 24px;
  }

  &__info {
    h4 {
      margin-bottom: 12px;
    }

    p {
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }
}
