.confirm-modal {
  position: relative;
  padding: 40px 64px;

  &__close {
    position: absolute;
    right: 8px;
    top: 8px;
    display: inline-flex;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
  }

  &__title {
    margin-bottom: 16px;
    font-family: "Bona Nova", serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 95%;
    text-align: center;
    color: #292929;
  }

  &__body {
    margin-bottom: 32px;
    text-align: center;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #222;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  &__cancel {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #1d6ef6;

    &:hover {
      text-decoration: underline;
    }
  }
}