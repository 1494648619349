.quests {
  position: relative;
  padding: 60px 0;
  background-color: #e8daf9;

  @include media($lg) {
    padding: 32px 0;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    width: 100%;
    background-repeat: no-repeat;
    transform: translateX(-50%);

    @include media($sm) {
      background-size: cover;
    }

    @include min-media($xxxl) {
      background-size: cover;
    }
  }

  &__inner {
    display: flex;
    gap: 16px;

    @include media($lg) {
      flex-direction: column;
      gap: 36px;
    }

    @include media($sm) {
      flex-direction: column;
      gap: 23px;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include min-media($lg) {
      max-width: 537px;
    }
  }

  &__right {
    width: 100%;
  }

  &__title {
    margin-bottom: 15px;

    @include media($sm) {
      margin-bottom: 16px;
      font-size: 24px;
    }
  }

  &__descr {
    margin-bottom: 22px;
    font-weight: 500;
    font-size: 18px;
    line-height: 135%;
    color: #181b2e;

    @include media($sm) {
      font-size: 14px;
      line-height: 146%;
    }
  }

  &__box {
    margin-top: auto;
    padding: 24px 36px;
    border-radius: 23px;
    background-color: #fff;

    @include media($sm) {
      padding: 18px 10px;
    }

    &--primary {
      max-width: 528px;

      @include media($lg) {
        max-width: 100%;
      }
    }
  }

  &__labels {
    display: flex;
    margin-bottom: 16px;
    gap: 8px;

    @include media($sm) {
      margin-bottom: 20px;
    }
  }

  &__label {
    position: relative;
    align-content: center;
    padding: 4px 12px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 135%;
    text-transform: uppercase;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-size: cover;
      transform: translateY(-50%);
    }

    &--primary {
      color: #7d4ce7;
      background-color: rgba(125, 76, 231, 0.2);
    }

    &--accent {
      color: #219ad3;
      background-color: rgba(33, 154, 211, 0.2);
    }

    &--correct {
      display: none;
      padding-left: 24px;
      font-weight: 700;
      font-size: 18px;
      text-transform: inherit;
      color: #1db555;

      &::before {
        background-image: url("#{$path-img}quests/right.svg");
      }
    }

    &--uncorrect {
      display: none;
      padding-left: 24px;
      font-weight: 700;
      font-size: 18px;
      text-transform: inherit;
      color: #d71a00;

      &::before {
        background-image: url("#{$path-img}quests/error.png");
      }
    }

    &--active {
      display: block;
    }
  }

  &__text {
    letter-spacing: -1px;

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 135%;
      color: #181b2e;

      @include media($sm) {
        font-size: 13px;
        line-height: 141%;
        letter-spacing: 0;
      }

      mark {
        font-weight: 600;
      }
    }

    > * {
      &:not(:last-child) {
        margin-bottom: 6px;

        @include media($sm) {
          margin-bottom: 13px;
        }
      }
    }

    ul {
      padding-left: 24px;
    }

    li {
      list-style-type: disc;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 4px;

      @include media($sm) {
        margin-bottom: 8px;
      }
    }
  }

  &__wrap {
    margin: 16px 0 0;

    @include media($sm) {
      margin: 22px 0 0;
    }
  }

  &__subtitle {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 135%;
    color: #181b2e;

    @include media($sm) {
      font-size: 13px;
    }
  }

  &__element {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__variant {
    position: relative;
    padding-left: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    color: #181b2e;
    cursor: pointer;

    @include media($sm) {
      padding-left: 20px;
      font-size: 13px;
      line-height: 155%;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #fff;
      transform: translateY(-50%);

      @include media($sm) {
        width: 12px;
        height: 12px;
      }
    }

    &--primary {
      &::before {
        border: 1px solid #61a1ca;
      }
    }

    &--accent {
      color: #292929;

      &::before {
        border: 1px solid #f68b69;
      }
    }

    &.correct {
      &::before {
        border: none;
        background-image: url("#{$path-img}quests/right.svg");
        background-size: cover;
      }
    }

    &.uncorrect {
      color: #ff3b30;

      &::before {
        border: none;
        background-image: url("#{$path-img}quests/error.png");
        background-size: cover;
      }
    }

    &.faithful {
      color: #00ad42;

      &::before {
        border: none;
        background-image: url("#{$path-img}quests/right.svg");
        background-size: cover;
      }
    }
  }

  &__link {
    display: none;
    margin-top: 16px;

    @include media($sm) {
      width: 100%;
    }

    &.visible {
      display: inline-flex;
    }
  }
}
