.pupil {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  line-height: 100%;

  &__left {
    display: flex;
    font-size: 14px;
    color: #292929;
    gap: 8px;
  }

  &__class {
    color: #949494;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
  }

  &__edit {
    font-size: 14px;
    color: #1d6ef6;
  }

  &__status {
    font-size: 12px;
    color: #fa9800;
  }

  &__delete {
    cursor: pointer;
  }
}