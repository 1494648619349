.awards {
  .container {
    @include media($sm) {
      padding: 0;
    }
  }

  &__title {
    margin-bottom: 8px;
  }

  &__descr {
    margin-bottom: 43px;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    color: #292929;

    @include media($sm) {
      margin-bottom: 24px;
      font-size: 14px;
    }
  }

  &__items {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 20px;

    @include media($lg) {
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 48px;
      gap: 20px;
    }

    @include media($sm) {
      margin-bottom: 24px;
    }
  }

  &__block {
    position: relative;
    padding: 35px 40px;
    min-height: 440px;
    overflow: hidden;
    border-radius: 24px;
    background-repeat: no-repeat;
    background-color: #f5f5f5;

    @include media($lg) {
      margin: 0 auto;
    }

    @include media($sm) {
      padding: 24px 12px;
      min-height: 300px;
    }

    &--book {
      max-width: 600px;
      background-image: url("#{$path-img}awards/img1.png"), url("#{$path-img}awards/bg1.png");
      background-position: right 35px bottom, 100% 100%;
      background-size: 261px 320px, 370px 240px;

      @include media($sm) {
        background-position: right -12px bottom -50px, 100% 100%;
        background-size: 191px 214px, 200px 120px;
      }
    }

    &--memo {
      max-width: 600px;
      background-image: url("#{$path-img}awards/img2.png"), url("#{$path-img}awards/bg2.png");
      background-position: right 20px bottom, 100% 100%;
      background-size: 295px 158px, 375px 230px;
      background-color: #7d4ce7;

      @include media($sm) {
        min-height: 370px;
        background-position: right 25px bottom -50px, 100% 100%;
        background-size: 179px 168px, 220px 131px;
      }
    }

    &--materials {
      display: flex;
      padding: 50px 590px 50px 40px;
      min-height: 400px;

      @include media($lg) {
        flex-direction: column;
        padding: 30px;
      }

      @include media($sm) {
        margin: 0 -12px;
        padding: 20px 12px;
        min-height: 320px;
        text-align: center;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: inherit;
  }

  &__subtitle {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 24px;
    line-height: 135%;
    color: #292929;

    @include media($sm) {
      margin-bottom: 12px;
      font-size: 16px;
    }

    &--memo {
      color: #fff;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #292929;

    @include media($sm) {
      font-size: 14px;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    &--memo {
      color: #fff;
    }

    &--mobile {
      display: none;

      @include media($lg) {
        display: block;
        padding: 0 10px;
      }
    }

    &--bottom {
      margin-top: auto;

      @include media($lg) {
        display: none;
      }
    }
  }

  &__images {
    position: absolute;
    right: 42px;
    bottom: -52px;
    width: 535px;
    height: 300px;

    @include media($lg) {
      position: relative;
      left: 50%;
      right: auto;
      bottom: 0;
      margin-bottom: 12px;
      width: 295px;
      height: 269px;
      transform: translateX(-50%);
    }
  }

  &__img {
    position: absolute;
    bottom: 0;
    overflow: hidden;
    border-radius: 6px;
    transition: 0.3s;

    &:hover {
      z-index: 3;
    }

    &--first {
      left: 0;
      bottom: -66px;
      z-index: 2;

      @include media($lg) {
        bottom: 0;
        width: 132px;
        height: 185px;
      }
    }

    &--second {
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);

      @include media($lg) {
        top: 0;
        width: 165px;
        height: 232px;
      }
    }

    &--third {
      right: 0;
      z-index: 2;

      @include media($lg) {
        bottom: 20px;
        width: 133px;
        height: 187px;
      }
    }
  }

  &__elements {
    display: flex;
    align-items: center;
    margin: 16px 0;
    gap: 8px;

    @include media($sm) {
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  &__element {
    padding: 6px 16px;
    border-radius: 10px;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: #7d4ce7;
    background-color: rgba(125, 76, 231, 0.22);

    @include media($sm) {
      font-size: 10px;
    }
  }
}
