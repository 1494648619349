$sm-height: 36px;
$md-height: 40px;
$lg-height: 50px;
$xl-height: 60px;

.btn {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: none;
  text-align: center;
  cursor: pointer;

  &[disabled] {
    border: none !important;
    box-shadow: none !important;
    color: #76767a !important;
    background-image: none !important;
    background-color: #c8c8c9 !important;
    cursor: not-allowed !important;
  }

  &--left-addons {
    svg {
      margin: 0 8px 0 0;
    }
  }

  &--right-addons {
    svg {
      margin: 0 0 0 8px;
    }
  }

  &--sm {
    padding: 0 8px;
    min-height: $sm-height;
    font-weight: 400;
    font-size: 14px;
  }

  &--md {
    padding: 0 16px;
    min-height: $md-height;
    font-weight: 600;
    font-size: 14px;
  }

  &--lg {
    padding: 0 24px;
    min-height: $lg-height;
    font-weight: 600;
    font-size: 16px;
  }

  &--xl {
    padding: 0 36px;
    min-height: $xl-height;
    font-weight: 700;
    font-size: 18px;

    @include media($xl) {
      padding: 0 32px;
    }

    @include media($sm) {
      padding: 0 16px;
      min-height: 60px;
      font-size: 18px;
    }
  }

  &--100 {
    width: 100%;
  }

  &--center {
    display: flex;
    margin: 0 auto;
    width: fit-content;
  }

  &--svg {
    padding: 0;

    svg {
      margin: 0;
    }

    &.btn--sm {
      width: $sm-height;
    }

    &.btn--md {
      width: $md-height;
    }

    &.btn--lg {
      width: $lg-height;
    }

    &.btn--xl {
      width: $xl-height;
    }
  }

  &--base {
    border-radius: 6px;
    color: #fff;
    background-color: #ff7c01;

    @include media($sm) {
      border-radius: 6px;
    }

    &:hover {
      box-shadow: 0 -2px 1px 0 rgba(0, 0, 0, 0.14) inset, 0 5px 11px 0 rgba(255, 121, 85, 0.82);
    }

    &:active {
      box-shadow: 0 -2px 1px 0 rgba(0, 0, 0, 0) inset;
    }
  }

  &--outline-base {
    border: 2px solid #ff7c01;
    border-radius: 6px;
    box-shadow: 0 -2px 1px 0 rgba(0, 0, 0, 0.14) inset;
    color: #292929;
    background-color: transparent;

    @include media($sm) {
      border-radius: 6px;
    }

    &:hover {
      box-shadow: inset 0 -2px 1px 0 rgba(0, 0, 0, 0.14);
      color: #fff;
      background-color: #ff7c01;
    }

    &:active {
      box-shadow: 0 -2px 1px 0 rgba(0, 0, 0, 0) inset;
      color: #fff;
      background-image: linear-gradient(275deg, #fe5021 4.48%, #ff6b43 92.47%);
    }
  }

  &--accent {
    border-radius: 15px;
    box-shadow: 0 -2px 1px 0 rgba(0, 0, 0, 0.14) inset;
    color: #002a27;
    background-image: linear-gradient(94deg, #82ffc3 0.99%, #56ffff 99.03%);

    @include media($sm) {
      border-radius: 6px;
    }

    &:hover {
      box-shadow: 0 -2px 1px 0 rgba(0, 0, 0, 0.14) inset, 0 4px 6.7px 0 #482b96;
    }

    &:active {
      box-shadow: 0 -2px 1px 0 rgba(0, 0, 0, 0) inset;
    }
  }

  &--outline-secondary {
    border: 2px solid #fff;
    border-radius: 15px;
    color: #fff;
    background-color: transparent;
  }

  &--reject {
    border-radius: 5px;
    box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.28);
    color: #fff;
    background-color: #ff3d00;

    &:hover {
      box-shadow: 0 3px 9px rgba(255, 61, 0, 0.5), inset 0 -2px 1px rgba(0, 0, 0, 0.28);
      background-color: #ff3d00;
    }

    &:active {
      box-shadow: inset 0 -2px 5px rgba(34, 34, 34, 0.3);
      background-color: #ff3d00;
    }
  }

  &.a-load-button {
    text-decoration: none !important;
    background-image:
      linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.3) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.3) 50%,
        rgba(255, 255, 255, 0.3) 75%,
        transparent 75%,
        transparent
      ) !important;
    background-size: 40px 40px !important;
    background-color: #99dd63 !important;
    animation: progress-bar-stripes 2s linear infinite;
    cursor: no-drop;
    pointer-events: none;
  }
}
