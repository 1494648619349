.attention {
  padding: 60px 0;
  background-image: url("#{$path-img}attention/fon-left.png"), url("#{$path-img}attention/fon-right.png");
  background-position: top -20px left, bottom right;
  background-repeat: no-repeat;
  background-color: #391e73;

  @include media($lg) {
    padding: 20px 0;
    background-position: 16% -11%, left 36% bottom 0%;
  }

  @include media($sm) {
    padding: 24px 0;
  }

  &__container {
    color: #ff7c01;
    background-image: url("#{$path-img}attention/bg-img.png");
    background-position: right 20px top -18px;
    background-repeat: no-repeat;

    @include media($lg) {
      background-image: none;
    }
  }

  &__title {
    margin-bottom: 16px;
    color: #ff7c01;
  }

  &__description {
    margin-bottom: 35px;
    max-width: 600px;
    font-weight: 700;
    font-size: 24px;
    color: #fff;

    @include media($lg) {
      max-width: 100%;
    }

    @include media($lg) {
      margin-bottom: 29px;
      font-size: 16px;
    }
  }

  &__items {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    gap: 32px;

    @include media($lg) {
      flex-direction: column;
    }

    @include media($sm) {
      margin-bottom: 46px;
      gap: 20px;
    }
  }

  &__item {
    width: 100%;
    max-width: 590px;

    @include media($lg) {
      max-width: 100%;
    }

    &:nth-child(1) {
      max-width: 545px;

      @include media($lg) {
        max-width: 100%;
      }
    }
  }

  &__img {
    display: block;
    margin-bottom: 12px;
  }

  &__text {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #fff;

    @include media($lg) {
      font-size: 14px;
    }

    &--big {
      font-size: 24px;
      line-height: 140%;

      @include media($lg) {
        font-size: 14px;
        letter-spacing: 0.3px;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    padding: 35px 32px;
    border-radius: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #292929;
    background-color: #fff;
    gap: 50px;

    @include media($lg) {
      flex-direction: column;
    }

    @include media($sm) {
      padding: 20px 8px;
      font-size: 14px;
      text-align: center;
      gap: 16px;
    }
  }

  &__link {
    flex-shrink: 0;

    @include media($lg) {
      width: 100%;
    }
  }
}