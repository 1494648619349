.system-message {
  margin: 32px 0 24px;
  padding: 24px;
  border-radius: 16px;
  background-color: #f9d357;

  @include media($sm) {
    padding: 16px;
  }

  &__title {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 95%;
    color: #292929;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__item {
    font-weight: 400;
    font-size: 16px;
    color: #181818;
  }

  &__email {
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 16px;

    @include media($sm) {
      flex-direction: column;
    }
  }

  &__cancel {
    color: #76767a;

    &:hover {
      text-decoration: underline;
    }
  }
}