.free-documents {
  .container {
    @include media($sm) {
      padding: 0;
    }
  }

  &__title {
    margin-bottom: 40px;

    @include media($sm) {
      margin-bottom: 30px;
    }

    mark {
      color: #ff7c01;
    }
  }

  &__items {
    display: flex;
    justify-content: space-between;
    padding: 40px 106px;
    border-radius: 20px;
    background-color: #f5f5f5;
    gap: 20px;

    @include media($lg) {
      flex-direction: column;
      align-items: center;
    }

    @include media($sm) {
      padding: 20px;
      gap: 26px;
    }
  }

  &__images {
    position: relative;
    margin-bottom: 22px;
    width: 393px;
    height: 444px;

    @include media($sm) {
      margin-bottom: 19px;
      width: 252px;
      height: 285px;
    }
  }

  &__img {
    overflow: hidden;
    border-radius: 8px;
    transition: 0.3s;
    cursor: pointer;

    @include media($sm) {
      width: 172px;
      height: 241px;
    }

    &:hover {
      z-index: 2;
    }

    &--first {
      position: absolute;
      left: 0;
      top: 0;
    }

    &--second {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &__description {
    font-weight: 700;
    font-size: 24px;
    line-height: 135%;
    text-align: center;
    color: #292929;

    @include media($sm) {
      font-size: 16px;
    }
  }
}