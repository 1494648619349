.header {
  padding: 18px 0;

  &--course {
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: #5d3cb8;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 56px;
    max-width: 1327px;
    border-radius: 20px;
    background-color: #f5f5f5;

    @include media($xl) {
      flex-wrap: wrap;
      padding: 12px 30px;
      gap: 24px;
    }

    @include media($sm) {
      padding: 8px 9px;
      gap: 20px;
    }

    &::after {
      display: none;
    }
  }

  &__link {
    display: block;
  }

  &__logo {
    display: block;

    @include media($sm) {
      max-width: 134px;
    }
  }

  &__items {
    display: flex;

    @include media($xl) {
      flex-basis: 100%;
      justify-content: center;
      order: 2;
    }

    @include media($md) {
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 16px 16px;
    }

    @include media($sm) {
      justify-content: space-between;
    }

    &--not-landing {
      @include media($md) {
        display: none;
      }
    }
  }

  &__item {
    position: relative;
    padding-left: 30px;
    min-width: 132px;
    background-image: url("#{$path-img}arrow.svg");
    background-position: left center;
    background-repeat: no-repeat;

    @include media($sm) {
      flex-basis: 142px;
    }

    &:not(:last-of-type) {
      margin-right: 40px;

      @include media($md) {
        margin-right: 0;
      }
    }
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
    color: #181b2e;

    @include media($sm) {
      font-size: 12px;
    }
  }

  &__date {
    margin-bottom: 6px;
    font-weight: 700;
    font-size: 16px;
    color: #181b2e;

    @include media($sm) {
      font-size: 14px;
    }
  }

  &__navs {
    display: flex;
    gap: 18px;

    @include media($sm) {
      gap: 8px;
    }
  }

  &__login {
    @include media($sm) {
      padding: 13px 24px 14px;
      min-height: 40px;
      font-size: 12px;
    }
  }
}
