.archive {
  margin-top: 48px;
  margin-bottom: 96px;

  @include media($xs) {
    margin: 24px 0 36px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include media($sm) {
      gap: 12px;
    }

    a {
      display: block;
      padding: 24px;
      border-radius: 16px;
      box-shadow: 0 2px 8px 0 rgba(34, 34, 34, 0.08);
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      color: #292929;
      background-color: #fff;
      cursor: pointer;

      &:hover {
        box-shadow: 0 12px 32px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.06);
        color: #1d6ef6;
      }
    }
  }

  &__title {
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 48px;
    line-height: 95%;
    text-align: center;
    color: #292929;

    @include media($sm) {
      margin-bottom: 24px;
      font-size: 32px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #222;

    @include media($sm) {
      font-size: 14px;
    }
  }
}