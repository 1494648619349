.a-di-vind {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: #555;
  background: rgba(0, 0, 0, 0.5);
}

.a-di-non-vind {
  position: relative;
  z-index: 1001;
  margin: 0 auto;
  width: 100%;
  height: auto;
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 16px 52px -5px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  @include media($md) {
    max-width: 100%;

    .a-di-top {
      .dr-di-l {
        overflow: hidden;

        b {
          float: left;
        }

        span {
          float: left;
        }
      }
    }

    .a-info-fechka {
      display: block;
      max-width: 100%;
    }

    .a-wiz-center .row {
      input,
      select,
      textarea {
        width: 100%;
      }
    }

    .a-wiz-center .blok label.a-label {
      width: calc(100% - 60px);
    }

    .a-dopinfo-fechka {
      display: block;
      max-width: 100%;
    }
  }

  iframe {
    display: block;
    margin: 21px auto;
    max-width: 100%;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);

    &.dr-kursy-test-dlg {
      margin: -10px 0 12px;
      box-shadow: none;

      @include media($md) {
        margin: 0;
      }
    }
  }

  @media (max-width: 400px) {
    a.a-fechka-dobavit {
      float: left;
      margin-top: -10px;
      padding: 6px 15px;
    }

    .a-wiz-center .blok label.a-label {
      padding-top: 0;
    }
  }
}

.dg-form {
  display: flex;
  padding: 30px 20px;

  @include media($md) {
    flex-direction: column;
    gap: 16px;
  }

  label {
    margin-bottom: 8px;
  }

  &-login {
    float: left;
    margin-right: 2%;
    width: 49%;

    @include media($md) {
      width: 100%;
    }
  }

  &-reg {
    float: right;
    width: 49%;

    @include media($md) {
      width: 100%;
    }
  }

  & h3 {
    margin: 0 0 20px;
    font-size: 16px;
    text-align: center;
  }

  hr {
    height: 0;
    border: 0;
    border-bottom: 4px solid #ff673d;
  }
}

.a-form-login {
  @include media($md) {
    label {
      margin-bottom: 5px;
      width: auto !important;
    }
  }

  .form {
    form {
      p {
        margin-bottom: 10px;
      }

      ul {
        margin-top: 5px;
        padding: 0 0 0 15px;
        font-size: 10px;
        color: #bbb;

        li {
          position: relative;
          margin-bottom: 5px;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            color: #0e0e0e;
          }

          a {
            color: #709dbb;
          }
        }
      }

      .column_2_1_1 {
        p.note {
          float: right;
          margin-right: 10px;
          color: #ccc;
        }
      }

      label {
        display: inline-block;
        padding-right: 10px;
        text-align: right;
      }

      input,
      select {
        display: inline-block;
        padding: 8px;
        width: 100%;
        height: 36px;
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        font-size: 14px;
      }

      .buttom {
        padding: 8px;
        border-radius: 4px;
        font-weight: 600;
        color: #fff;
        background: #1eb92e;
        cursor: pointer;

        &:hover {
          background: #34c042;
        }
      }

      input[type="checkbox"] {
        display: inline-block;
        width: 20px;
      }
    }
  }
}

.a-form-info-mini {
  font-weight: 500;
}

form {
  .row {
    display: block;
    margin-bottom: 10px;
  }

  label {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
  }

  .kr-for-math {
    select {
      display: block;

      @include media($md) {
        width: 100%;
      }
    }
  }

  .a-inp-n {
    display: inline-block;
    font-size: 12px;
    color: #999;
  }

  .dr-formRadio-inline {
    input[type="radio"] {
      min-height: inherit;
    }
  }

  input[type="checkbox"] {
    margin-top: -1px;
    margin-right: 5px;
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }
}

.a-di-non-upss-r {
  position: fixed;
  left: 50%;
  top: 30%;
  z-index: 1001;
  margin: 0 0 10px -250px;
  padding: 20px;
  width: 100%;
  height: auto;
  border-radius: 16px;
  font-size: 16px;
  color: #fff;
  background: #dd3e31;
  background: rgba(165, 18, 6, 0.85);

  @include media($md) {
    left: 15px;
    top: calc(50vh - 60px);
    margin: 0;
    max-width: calc(100% - 30px);
  }

  .errorSummary {
    color: #fff;
  }

  a {
    font-weight: 900;
    text-decoration: underline;
    color: #fff;
  }

  .a-dell {
    float: right;
    margin-left: 7px;
    padding: 1px 8px 3px;
    border-radius: 4px;
    font-weight: 900;
    cursor: pointer;

    &:hover {
      background: #892d25;
    }
  }
}

.a-di-non-upss {
  position: fixed;
  left: 0;
  right: 0;
  top: 30%;
  z-index: 1001;
  margin: 0 auto 10px;
  padding: 20px;
  width: 100%;
  height: auto;
  max-width: 90%;
  font-size: 14px;
  line-height: 1.25;
  color: #fff;
  background: #555;
  background: rgba(0, 0, 0, 0.8);

  @include media($md) {
    top: 20px;
  }

  a {
    font-weight: 900;
    color: #aad2e6;
  }

  .a-dell {
    float: right;
    margin-left: 6px;

    //@include border-radius(4px);
    padding: 1px 8px 3px;
    font-weight: 900;
    cursor: pointer;

    &:hover {
      background: #7c7c7c;
    }
  }
}

.errorSummary {
  margin: 0 0 20px;
  font-size: 0.9em;
  color: #c00;

  p {
    margin: 0;
    padding: 5px;
  }

  ul {
    margin: 0;
    padding: 0 0 0 20px;

    li {
      list-style-type: disc;
      font-weight: 900;
    }
  }
}

.errorMessage {
  font-size: 0.9em;
  color: red;
}

.a-di-top {
  margin-bottom: 32px;
  padding: 40px 64px 0;
  border-radius: 18px 18px 0 0;
  font-family: "Bona Nova", serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 95%;
  text-align: left;
  color: #292929;

  @include media($sm) {
    padding: 24px 24px 0;
    font-size: 32px;
  }

  p {
    margin: 0;

    &.a-di-r {
      button {
        position: absolute;
        right: 8px;
        top: 8px;
        display: inline-flex;
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent;
      }
    }
  }
}

.a-di-non-upss-r {
  position: fixed;
  left: 50%;
  top: 30%;
  z-index: 1001;
  margin: 0 0 10px -250px;
  padding: 20px;
  width: 100%;
  height: auto;
  border-radius: 16px;
  font-size: 16px;
  color: #fff;
  background: #dd3e31;
  background: rgba(165, 18, 6, 0.85);

  @include media($md) {
    left: 15px;
    top: calc(50vh - 60px);
    margin: 0;
    max-width: calc(100% - 30px);
  }

  .errorSummary {
    color: #fff;
  }

  a {
    font-weight: 900;
    text-decoration: underline;
    color: #fff;
  }

  .a-dell {
    float: right;
    margin-left: 7px;
    padding: 1px 8px 3px;
    border-radius: 4px;
    font-weight: 900;
    cursor: pointer;

    &:hover {
      background: #892d25;
    }
  }
}

.a-di-non-upss {
  position: fixed;
  left: 0;
  right: 0;
  top: 30%;
  z-index: 1001;
  margin: 0 auto 10px;
  padding: 20px;
  width: 100%;
  height: auto;
  max-width: 90%;
  border-radius: 4px;
  box-shadow: 0 0 15px -5px #eee;
  font-size: 14px;
  line-height: 1.25;
  color: #fff;
  background: #555;
  background: rgba(0, 0, 0, 0.8);

  @include media($md) {
    top: 20px;
  }

  a {
    font-weight: 900;
    color: #aad2e6;
  }

  .a-dell {
    float: right;
    margin-left: 6px;
    padding: 1px 8px 3px;
    border-radius: 4px;
    font-weight: 900;
    cursor: pointer;

    &:hover {
      background: #7c7c7c;
    }
  }
}

.dr-dlg-center-wrap {
  padding: 0 64px 40px;

  p {
    margin: 0 0 10px;
  }

  label {
    display: block;
    padding: 5px 0;
  }

  input {
    padding: 8px;
    width: 100%;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    font-size: 14px;

    &[type="submit"] {
      margin-top: 24px;
    }
  }
}
