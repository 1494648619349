.settings {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 96px;

  @include media($lg) {
    flex-wrap: wrap;
  }

  @include media($sm) {
    margin-bottom: 64px;
  }

  .tabs {
    display: flex;
    margin-bottom: 40px;
    border-bottom: 1px solid #dadada;
    color: #fff;
    gap: 24px;

    @include media($md) {
      margin-bottom: 36px;
    }

    li {
      a {
        padding: 0 16px 16px;

        &.active {
          display: block;
          border-bottom: 3px solid #f4d668;
          font-weight: 600;
          font-size: 16px;
          line-height: 95%;
          color: #fff;
        }
      }
    }
  }

  &__inner {
    width: 100%;
    max-width: 858px;

    @include media($lg) {
      margin-bottom: 36px;
    }
  }

  &__title {
    margin-bottom: 64px;
    font-weight: 700;
    font-size: 48px;
    line-height: 95%;
    color: #fff;

    @include media($md) {
      display: none;
    }
  }
}
