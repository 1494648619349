.orders {
  margin-bottom: 96px;
  width: 100%;
  max-width: 640px;

  @include media($xl) {
    order: 3;
    margin-bottom: 0;
    max-width: 100%;
  }

  @include media($sm) {
    margin-bottom: 36px;
  }

  &__no-order {
    margin-bottom: 40px;
    max-width: 450px;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.16px;
    color: #fff;

    p {
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }

      a {
        text-decoration-line: underline;
        color: #fff;

        &:hover {
          text-decoration-line: none;
        }
      }
    }
  }

  &__title {
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 48px;
    line-height: 95%;
    color: #fff;

    @include media($sm) {
      display: none;
    }
  }

  &__alert {
    margin-bottom: 24px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.14px;
      color: #0f0f0f;

      a {
        color: #1d6ef6;

        &:hover {
          text-decoration: underline;
        }
      }

      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
  }

  &__filters {
    display: flex;
    margin-bottom: 24px;
    border-bottom: 1px solid #fff;
    gap: 24px;

    @include media($sm) {
      margin-bottom: 30px;
      gap: 16px;
    }
  }

  &__filter {
    padding: 0 16px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 95%;
    color: #fff;

    @include media($sm) {
      padding: 0 12px 12px;
      font-size: 14px;
    }

    &.active {
      border-bottom: 3px solid #f4d668;
      color: #fff;
    }
  }

  &__prize {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    gap: 12px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.14px;
      color: #0f0f0f;
    }
  }

  &__download {
    margin-bottom: 32px;
  }

  &__btn {
    width: fit-content;
  }
}
