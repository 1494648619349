html {
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Mulish", sans-serif;
}

.container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1220px;

  @include media($xl) {
    padding: 0 10px;
  }
}

.content {
  overflow: hidden;
}

.section {
  margin-bottom: 120px;

  @include media($xl) {
    margin-bottom: 80px;
  }

  @include media($lg) {
    margin-bottom: 60px;
  }

  @include media($sm) {
    margin-bottom: 40px;
  }
}

.title {
  font-weight: 700;
  font-size: 48px;
  color: #292929;

  @include media($sm) {
    font-size: 24px;
  }

  &--center {
    text-align: center;
  }
}

.a-di-non-vind {
  @include media($sm) {
    width: 90% !important;
  }
}
