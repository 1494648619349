.attention-block {
  padding: 24px 16px;
  max-width: 240px;
  border-radius: 16px;
  box-shadow: 0 2px 8px 0 rgba(34, 34, 34, 0.08);
  font-size: 14px;
  background-color: #fff;

  @include media($sm) {
    width: 100%;
    max-width: 100%;
  }

  &__title {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.18px;
    color: #222;
  }

  &__order {
    margin-bottom: 16px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.14px;
    color: #ff3b30;
  }

  &__text {
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.14px;
    color: #949494;

    mark {
      font-weight: 600;
      color: #000;
    }
  }

  &__codes {
    margin-top: 16px;

    p {
      margin-bottom: 8px;
    }
  }
}