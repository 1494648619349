.footer {
  margin-top: auto;
  padding: 40px 0;
  background-color: #1a1a1a;

  @include media($sm) {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  &__container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @include media($xl) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
    }
  }

  &__logo {
    display: block;
    margin-bottom: 10px;

    img {
      display: block;
      height: auto;
    }
  }

  &__copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #fff;

    @include media($sm) {
      margin-bottom: 4px;
    }
  }

  &__support {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #949494;
  }

  &__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    text-decoration-line: none;
    color: #f4f7ff;

    @include media($sm) {
      display: block;
      line-height: 1.2;
    }

    &:hover {
      text-decoration-line: underline;
    }

    &--500 {
      font-weight: 500;
    }
  }

  &__items {
    display: grid;
    grid-gap: 24px 80px;
    grid-template-columns: repeat(3, auto);

    @include media($lg) {
      flex-direction: column;
      gap: 8px;
    }

    @include media($sm) {
      grid-template-columns: repeat(1, auto);
      gap: 26px;
    }
  }

  &__item {
    width: fit-content;
  }
}
