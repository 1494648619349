.test-answers {
  padding: 0 64px 40px;
  color: #292929;

  @include media($sm) {
    padding: 0 24px 24px;
  }

  &__name {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.24px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 16px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.14px;
    background-color: #eff8f6;
    gap: 8px;
  }

  &__left {
    text-align: left;

    h3 {
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 16px;
    }

    p {
      &:not(:last-of-type) {
        margin-bottom: 6px;
      }
    }
  }

  &__right {
    text-align: right;

    p {
      &:not(:last-of-type) {
        margin-bottom: 6px;
      }
    }
  }
}