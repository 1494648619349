.safety-rules {
  @include media($sm) {
    margin-bottom: 13px;
  }

  &__title {
    margin: 0 auto 63px;
    max-width: 920px;

    @include media($sm) {
      margin: 0 auto 68px;
    }

    mark {
      display: inline-block;
      padding: 0 10px 5px;
      border-radius: 20px;
      color: #fff;
      background-color: #7d4ce7;

      @include media($sm) {
        padding: 0 6px 5px;
      }
    }
  }

  &__items {
    position: relative;
    display: flex;
    justify-content: space-between;
    min-height: 200px;

    @include media($md) {
      min-height: 0;
    }

    &::before {
      content: "";
      position: absolute;
      right: -2%;
      display: block;
      width: 104%;
      height: 200px;
      background-image: url("#{$path-img}safety-rules/line.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      @include media($md) {
        right: 0;
        width: 100%;
        height: 100px;
      }

      @include media($xs) {
        right: 0;
        top: -14px;
        height: 40px;
      }
    }
  }

  &__item {
    position: relative;
    padding-top: 85px;
    width: fit-content;
    font-weight: 700;
    font-size: 24px;
    line-height: 135%;
    color: #292929;
    background-image: url("#{$path-img}safety-rules/label.png");
    background-position: top center;
    background-repeat: no-repeat;

    @include media($md) {
      padding-top: 40px;
      min-height: 0;
      font-size: 16px;
      background-size: 27px 33px;
    }

    @include media($sm) {
      font-size: 10px;
    }

    &:nth-child(1) {
      right: -53px;
      top: -14px;

      @include media($xs) {
        right: -3px;
        top: -41px;
      }
    }

    &:nth-child(2) {
      right: -9px;
      top: 60px;

      @include media($md) {
        right: -9px;
        top: 0;
      }

      @include media($xs) {
        right: 5px;
        top: -27px;
      }
    }

    &:nth-child(3) {
      right: -46px;
      top: 2px;

      @include media($xs) {
        right: -2px;
        top: -25px;
      }
    }

    &:nth-child(4) {
      right: 31px;
      top: 32px;

      @include media($md) {
        right: 31px;
        top: -15px;
      }

      @include media($xs) {
        right: 1px;
        top: -41px;
      }
    }
  }
}