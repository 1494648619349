.awards-page {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.16px;
  color: #fff;

  &__back {
    display: inline-flex;
    align-items: center;
    margin-bottom: 24px;
    padding: 0 24px;
    min-height: 50px;
    border: 1px solid #4fb546;
    border-radius: 52px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.14px;
    color: #fff;

    &:hover {
      color: #fff;
      background-color: #4fb546;
    }

    svg {
      margin-right: 8px;
    }
  }

  &__title {
    margin-bottom: 24px;
    font-family: "Bona Nova", serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 95%;
    color: #fff;
  }

  &__subtitle {
    margin-bottom: 8px;
  }

  &__text {
    margin-bottom: 16px;

    p {
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__awards {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__download {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.14px;
    color: #FFDE00;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__application {
    margin-bottom: 16px;
  }

  &__pupils {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    tbody {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    tr {
      display: flex;
      align-items: baseline;
    }

    .dr-numb {
      margin-right: 8px;
    }

    .dr-award {
      display: flex;
      flex-wrap: wrap;
      gap: 8px 16px;
    }

    .dr-name {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }

    .dr-class {
      display: flex;
      align-items: center;
      margin-left: 8px;

      svg {
        margin-left: 12px;
      }
    }
  }

  &__btn {
    margin-top: 16px;
  }
}
