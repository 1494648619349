.orders-page {
  flex: 1;
  background-image: linear-gradient(180deg, #796bde 0%, #5229a8 100%);

  &__container {
    display: flex;
    align-items: flex-start;
    gap: 40px;

    @include media($xl) {
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-bottom: 64px;
      gap: 0 16px;
    }
  }
}
