.restore {
  margin: 120px auto 0;

  @include media($md) {
    margin-top: 48px;
  }

  &__wrapper {
    margin: 0 auto 235px;
    padding: 40px 64px;
    max-width: 676px;
    border-radius: 18px;
    box-shadow: 0 2px 8px 0 rgba(34, 34, 34, 0.08);
    background-color: #fff;

    @include media($md) {
      margin-bottom: 64px;
      padding: 32px 16px;
    }
  }

  &__title {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 48px;
    line-height: 95%;
    text-align: center;
    color: #292929;

    @include media($sm) {
      margin-bottom: 16px;
      font-size: 32px;
    }
  }

  &__text {
    margin-bottom: 56px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #222;

    @include media($sm) {
      margin-bottom: 24px;
      font-size: 14px;
    }
  }

  &__form {
    margin: 0 auto;
    max-width: 410px;
  }

  &__btn {
    margin-top: 24px;

    @include media($sm) {
      margin-top: 16px;
    }
  }

  &__img {
    position: absolute;
    bottom: 0;

    @include media($md) {
      display: none;
    }
  }
}
