.what-useful {
  margin-top: -20px;

  @include media($lg) {
    margin-top: 0;
  }

  &__title {
    margin-bottom: 50px;

    @include media($sm) {
      margin-bottom: 22px;
    }
  }

  &__items {
    display: flex;
    margin-bottom: 58px;
    gap: 30px;

    @include media($lg) {
      flex-direction: column;
    }

    @include media($sm) {
      margin-bottom: 16px;
      gap: 14px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 28px 20px;
    width: 100%;
    max-width: 387px;
    border-radius: 30px;
    background-image: url("#{$path-img}what-useful/check.svg");
    background-position: right 18px top 25px;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-color: #f5f5f5;

    @include media($lg) {
      max-width: 100%;
    }

    @include media($sm) {
      padding: 20px 16px;
      background-position: right 14px top 14px;
      background-size: 32px 32px;
    }

    &--violet {
      background-color: #7d4ce7;
    }
  }

  &__name {
    margin-bottom: 24px;
    padding-right: 40px;
    font-weight: 600;
    font-size: 24px;
    line-height: 135%;
    color: #292929;

    @include media($sm) {
      margin-bottom: 13px;
      font-size: 16px;
    }

    &--white {
      color: #fff;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #292929;

    @include media($sm) {
      font-size: 13px;
      letter-spacing: -0.3px;
    }

    &--white {
      color: #fff;
    }
  }

  &__link {
    @include media($lg) {
      width: 100%;
    }
  }
}