.about {
  &__band {
    position: absolute;
    left: 50%;
    bottom: 10px;
    width: 1920px;
    transform: translateX(-50%);

    @include media($sm) {
      bottom: -10px;
      z-index: 10;
      width: 100%;
    }

    img {
      @include media($sm) {
        display: block;
        height: auto;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    gap: 48px;

    @include media($lg) {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__content {
    max-width: 540px;

    @include media($lg) {
      max-width: 100%;
    }
  }

  &__img {
    img {
      display: block;
      height: auto;
    }
  }

  &__info {
    padding-top: 24px;
    max-width: 522px;
  }

  &__title {
    margin-top: -20px;
    margin-bottom: 36px;

    @include media($lg) {
      margin-top: -3px;
    }

    @include media($sm) {
      margin-bottom: 22px;
      font-size: 24px;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #181b2e;

    @include media($sm) {
      font-size: 14px;
      line-height: 150%;
    }

    &:not(:last-child) {
      margin-bottom: 20px;

      @include media($sm) {
        margin-bottom: 10px;
      }
    }

    &--light {
      font-weight: 500;
      font-size: 16px;
      color: #949494;

      @include media($sm) {
        font-size: 12px;
      }
    }
  }

  &__items {
    width: 100%;
    min-width: 530px;
    max-width: 590px;

    @include media($lg) {
      min-width: 0;
      max-width: 100%;
    }
  }

  &__item {
    align-content: center;
    padding: 22px 120px 22px  95px;
    min-height: 87px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 135%;
    color: #181b2e;
    background-position: left 32px center;
    background-repeat: no-repeat;
    background-color: #e8daf9;

    @include media($sm) {
      padding: 8px 18px 8px 58px;
      min-height: 74px;
      font-size: 14px;
      line-height: 1;
      background-position: 16px 50%;
      background-size: 26px;
    }

    @include media($sm) {
      font-size: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;

      @include media($sm) {
        margin-bottom: 12px;
      }
    }

    &--opportunity {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        right: -12px;
        top: -32px;
        display: block;
        width: 110px;
        height: 90px;
        background-image: url("#{$path-img}about/opportunity-bg.svg");
        background-position: center;
        background-repeat: no-repeat;

        @include media($sm) {
          right: -19px;
          top: -41px;
          background-size: contain;
        }
      }
    }

    &--rewards {
      color: #fff;
      background-color: #7d4ce7;
    }

    @each $icon in "opportunity", "participation", "rewards", {
      &--#{$icon} {
        background-image: url("#{$path-img}about/#{$icon}.svg");
      }
    }
  }
}
