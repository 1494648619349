.ready-lesson {
  &__container {
    display: flex;
    align-items: center;
    padding: 34px 50px;
    border-radius: 20px;
    background-color: #7d4ce7;
    gap: 40px;

    @include media($lg) {
      flex-direction: column-reverse;
    }

    @include media($sm) {
      padding: 24px 12px 28px;
      gap: 20px;
    }
  }

  &__block {
    flex: 1 0 542px;

    @include media($lg) {
      flex: 0 0 auto;
    }
  }

  &__images {
    position: relative;
    margin-bottom: 16px;

    @include media($sm) {
      height: 146px;
    }
  }

  &__img {
    display: block;
    max-width: 490px;
    overflow: hidden;
    border-radius: 20px;

    @include media($sm) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 30%;
    }

    &--mini {
      position: absolute;
      right: 0;
      bottom: 0;

      @include media($lg) {
        display: none;
      }
    }
  }

  &__info {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #fff;

    @include media($lg) {
      letter-spacing: -0.3px;
    }

    br {
      @include media($lg) {
        display: none;
      }
    }
  }

  &__content {
    flex: 1 1 545px;

    @include media($lg) {
      flex: 0 0 auto;
    }
  }

  &__title {
    margin-bottom: 16px;
    color: #fff;

    @include media($sm) {
      margin-bottom: 22px;
      text-align: center;
    }
  }

  &__text {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #fff;

    @include media($sm) {
      font-size: 14px;
      letter-spacing: -0.06px;
      text-align: center;
    }

    span {
      font-weight: 700;
      font-size: 20px;

      @include media($sm) {
        font-size: 14px;
      }
    }

    &--bold {
      font-weight: 700;
      font-size: 16px;

      @include media($sm) {
        margin-bottom: 20px;
        font-size: 14px;
        text-align: center;
      }
    }
  }

  &__link {
    @include media($lg) {
      width: 100%;
    }
  }
}