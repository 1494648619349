.hero {
  @include media($md) {
    background-position: calc(50% - 76px) 126%;
    background-size: auto 540px;
  }

  @include media($sm) {
    padding-top: 0;
    background-position: calc(50% - 76px) 189%;
  }

  &__container {
    position: relative;
    display: flex;
    padding: 46px 58px;
    max-width: 1328px;
    border-radius: 20px;
    background-color: #f5f5f5;
    gap: 90px;

    @include media($xl) {
      flex-direction: column-reverse;
      padding: 30px;
    }

    @include media($sm) {
      padding: 20px 12px;
      gap: 20px;
    }
  }

  &__content {
    flex: 1 1 532px;

    @include media($xl) {
      flex: 0 0 auto;
    }
  }

  &__subtitle {
    display: inline-block;
    margin-bottom: 20px;
    padding: 4px 20px;
    border-radius: 20px;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #292929;
    background-color: #fff;

    @include media($sm) {
      margin-bottom: 16px;
      font-size: 16px;
    }

    &--pc {
      @include media($xl) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include media($xl) {
        display: block;
      }

      @include media($sm) {
        text-align: left;
      }
    }
  }

  &__title {
    margin-bottom: 32px;
    font-weight: 800;
    font-size: 66px;
    line-height: 1.1;
    color: #181b2e;

    @include media($sm) {
      margin-bottom: 18px;
      font-size: 36px;
      line-height: 1;
    }

    &--pc {
      @include media($xl) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include media($xl) {
        display: block;
      }
    }
  }

  &__pretitle {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 24px;
    color: #181b2e;

    @include media($sm) {
      margin-bottom: 16px;
      font-size: 16px;
    }
  }

  &__link {
    margin-bottom: 32px;

    @include media($xl) {
      width: 100%;
    }

    @include media($sm) {
      margin-bottom: 14px;
      width: 100%;
    }
  }

  &__items {
    margin-bottom: 42px;

    @include media($sm) {
      margin-bottom: 17px;
    }
  }

  &__item {
    padding-left: 32px;
    min-height: 24px;
    font-weight: 500;
    font-size: 18px;
    color: #181b2e;
    background-image: url("#{$path-img}hero/check.svg");
    background-position: left top;
    background-repeat: no-repeat;

    @include media($sm) {
      font-size: 16px;
      background-position: left 2px center;
      background-size: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;

      @include media($sm) {
        margin-bottom: 12px;
      }
    }

    mark {
      font-weight: 600;
    }

    &--flex {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  &__advises {
    padding: 6px 16px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #219ad3;
    background-color: rgba(33, 154, 211, 0.2);

    &--pc {
      @include media($xl) {
        display: none;
      }
    }

    &--mobile {
      display: none;

      @include media($xl) {
        display: inline-block;
        margin-bottom: 23px;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    gap: 12px;

    @include media($xl) {
      line-height: 1.2;
    }

    mark {
      color: #ff7c01;
    }
  }

  &__block {
    flex: 1 0 593px;

    @include media($xl) {
      flex: 0 0 auto;
    }
  }

  &__description {
    margin-bottom: 15px;
    padding: 17px 34px;
    border-radius: 20px;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    background-color: #7d4ce7;

    @include media($sm) {
      margin-bottom: 16px;
      padding: 4px 24px;
      font-size: 16px;
      text-align: left;
    }
  }

  &__images {
    position: relative;

    @include media($xl) {
      margin: 0 auto;
      max-width: 540px;
    }

    @include media($sm) {
      height: 146px;
    }
  }

  &__img {
    overflow: hidden;
    border-radius: 20px;

    @include media($xl) {
      width: 100%;
      object-fit: cover;
    }

    @include media($sm) {
      height: 100%;
      object-position: 50% 30%;
    }
  }

  &__circle {
    position: absolute;
    left: -72px;
    top: 42px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 163px;
    height: 163px;
    border-radius: 50%;
    text-align: center;
    background-image: url("#{$path-img}hero/circle.svg");
    background-size: cover;
    background-repeat: no-repeat;

    @include media($xl) {
      left: auto;
      right: 0;
      top: auto;
      bottom: -50px;
    }

    @include media($sm) {
      bottom: -41px;
      width: 121px;
      height: 121px;
      background-size: cover;
    }
  }

  &__text {
    font-weight: 600;
    font-size: 18px;
    color: #fff;

    @include media($sm) {
      font-size: 13px;
    }
  }

  &__price {
    font-weight: 700;
    font-size: 40px;
    color: #fff;

    @include media($sm) {
      margin-bottom: 6px;
      font-size: 29px;
    }
  }
}
