.participation {
  padding: 24px 16px;
  max-width: 240px;
  border-radius: 16px;
  box-shadow: 0 2px 8px 0 rgba(34, 34, 34, 0.08);
  background: #fff;

  @include media($xl) {
    order: 1;
    margin-bottom: 30px;
  }

  @include media($sm) {
    padding: 24px 8px;
    width: 100%;
    max-width: 100%;
  }

  &__title {
    padding-bottom: 16px;
    border-bottom: 1px solid #f9de80;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.18px;
    color: #222;

    @include media($sm) {
      margin-bottom: 20px;
      padding-bottom: 2px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 12px 12px;
    width: 100%;
    border: 0;
    border-radius: 8px;
    background-color: #f9d357;
  }

  &__text {
    display: flex;
    flex-direction: column;
    max-width: 110px;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    letter-spacing: 0.12px;
    text-align: left;
    color: #424242;

    mark {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.14px;
      color: #000;
    }
  }

  &__sum {
    padding: 8px;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.16px;
    color: #fff;
    background-color: #fa9800;
  }
}