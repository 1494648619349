@import "settings";
@import "setting";
@import "restore";

.bat {
  margin: 32px 0 48px;
  font-size: 18px;
  color: #fff;

  @include media($md) {
    margin: 28px 0 25px;
  }

  @include media($sm) {
    font-size: 14px;
  }

  a {
    font-weight: 500;

    span {
      color: #fff;
    }
  }
}
