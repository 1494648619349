.order {
  background-color: #7d4ce7;

  .container {
    @include media($sm) {
      padding: 0;
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    padding: 52px 0;
    min-height: 320px;
    background-image: url("#{$path-img}order/img-bg.png");
    background-position: center right;
    background-size: 250px 146px;
    background-repeat: no-repeat;
    gap: 20px;

    @include media($lg) {
      flex-direction: column;
      padding: 20px 0;
      background-position-x: center;
      background-position-y: calc(100% - 24px);
      background-size: 184px 123px;
    }

    @include media($sm) {
      padding: 15px 0;
      min-height: 270px;
    }
  }

  &__block {
    padding-bottom: 24px;
    width: 100%;
    max-width: 547px;

    @include media($lg) {
      margin: 0 auto 24px;
      max-width: 100%;
    }

    @include media($sm) {
      margin-bottom: 10px;
    }

    &--center {
      align-self: center;
    }

    &--bg {
      &::before {
        content: "";
        position: absolute;
        left: 10px;
        bottom: 40px;
        display: block;
        width: 250px;
        height: 146px;
        background-image: url("#{$path-img}order/img-bg.png");
        background-position: left bottom;
        background-size: 250px 146px;
        background-repeat: no-repeat;

        @include media($lg) {
          display: none;
        }
      }
    }

    &--arrow {
      background-image: url("#{$path-img}order/arrow.svg");
      background-position: top 150px right;
      background-size: 206px 71px;
      background-repeat: no-repeat;

      @include media($lg) {
        padding-bottom: 0;
        background-image: none;
      }
    }
  }

  &__content {
    padding: 40px 44px;
    width: 100%;
    max-width: 590px;
    border-radius: 20px;
    background-color: #fff;

    @include media($lg) {
      margin: 0 auto 140px;
    }

    @include media($sm) {
      padding: 32px 12px;
    }
  }

  &__title {
    margin-bottom: 16px;
    color: #fff;

    @include media($lg) {
      text-align: center;
    }

    @include media($sm) {
      margin-bottom: 16px;
      font-size: 24px;
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 16px;
  }

  &__description {
    align-self: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 135%;
    color: #fff;

    @include media($lg) {
      text-align: center;
    }

    @include media($sm) {
      padding: 0 20px;
      font-size: 16px;
    }
  }

  &__label {
    margin-bottom: 16px;
    width: 100%;

    span {
      display: block;
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 18px;
      line-height: 110%;
      color: #fff;
    }
  }

  &__input {
    padding: 16px;
    width: 100%;
    border: 1px solid #949494;
    border-radius: 6px;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #949494;

    &::placeholder {
      font-weight: 400;
      font-size: 18px;
      line-height: 1;
      color: #949494;
    }
  }

  &__box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    gap: 8px;

    @include media($lg) {
      flex-direction: column;
      gap: 20px;
    }

    @include media($sm) {
      flex-direction: column;
      margin-bottom: 24px;
    }
  }

  &__left {
    @include media($lg) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }
  }

  &__right {
    @include media($lg) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }
  }

  &__subtitle {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    color: #666;

    @include media($lg) {
      margin-bottom: 0;
    }

    @include media($sm) {
      font-size: 16px;
      text-align: left;
    }
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    max-width: 140px;
    border-radius: 59px;
    box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  &__btn {
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    transition: 0.3s;

    &:hover {
      opacity: 0.7;
    }

    svg {
      display: block;
    }
  }

  &__decrement {
    &--disabled {
      circle {
        fill: #dadada;
      }
    }
  }

  &__count-input {
    padding: 0;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 28px;
    text-align: center;
    color: #292929;

    @include media($sm) {
      font-size: 24px;
    }
  }

  &__sum {
    font-weight: 700;
    font-size: 26px;
    line-height: 135%;
    color: #292929;

    @include media($sm) {
      margin: 0;
      font-size: 24px;
    }
  }

  &__accept {
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    color: #666;

    a {
      text-decoration-line: underline;

      &:hover {
        text-decoration-line: none;
      }
    }
  }
}
